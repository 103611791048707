"use client";

import Image from "next/image";
import Link from "next/link";
import { isEmpty } from "radash";
import React, { useCallback, useEffect, useRef, useState } from "react";

import { useFeeds } from "@/api/usePostApi";
import useAnalytics from "@/hooks/useAnalytics";
import { useToast } from "@/hooks/useToast";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import {
  addFeeds,
  feedState,
  setFeeds,
  setIsInitialLoading,
} from "@/redux/reducers/feedReducer";

import InfiniteScroll from "./InfiniteScroll";
import PostList from "./post-list/PostList";

const FeedPage = () => {
  const dispatch = useAppDispatch();
  const { feeds, isInitialLoading } = useAppSelector(feedState);
  const { capture } = useAnalytics();

  const { loadMore, isMutating, refetch } = useFeeds();
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [hasAttemptedInitialFetch, setHasAttemptedInitialFetch] =
    useState(false);
  const { showToast } = useToast();

  const fetchInitialData = async () => {
    dispatch(setIsInitialLoading(true));
    setError(null);
    try {
      capture("get_feeds");
      const initialData = await refetch();
      if (initialData) {
        capture("get_feeds_result", {
          result: "success",
          length: initialData.length,
        });
        dispatch(setFeeds(initialData));
        setHasMore(initialData.length > 0);
      } else {
        capture("get_feeds_result", {
          result: "error",
          msg: "no data",
        });
      }
    } catch (err) {
      capture("get_feeds_result", {
        result: "error",
        msg: "error",
        error: err,
      });
      console.debug("Failed to fetch posts. Please try again.");
      setError("Failed to fetch posts. Please try again.");
    } finally {
      dispatch(setIsInitialLoading(false));
      setHasAttemptedInitialFetch(true);
    }
  };

  const handleLoadMore = async () => {
    setError(null);
    capture("get_feeds");
    try {
      const newPosts = await loadMore();
      if (newPosts) {
        capture("get_feeds_result", {
          result: "success",
          length: newPosts.length,
        });
        dispatch(addFeeds(newPosts));
        setHasMore(newPosts.length > 0);
      } else {
        capture("get_feeds_result", {
          result: "error",
          msg: "no data",
        });
      }
    } catch (err) {
      capture("get_feeds_result", {
        result: "error",
        msg: "error",
        error: err,
      });
      showToast({
        description: "Failed to load more posts. Please try again.",
        variant: "error",
      });
    }
  };

  const onPostClick = (postId: string) => {
    capture("post_click", {
      post_id: postId,
      source: "home-feed",
    });
  };

  useEffect(() => {
    if (isEmpty(feeds) && !hasAttemptedInitialFetch) {
      fetchInitialData();
    }
  }, [feeds, hasAttemptedInitialFetch]);

  return (
    <div className="flex grow flex-col items-center px-3 py-4">
      {/* Desktop Layout - Two Panel */}
      <div className="hidden w-full max-w-6xl md:flex md:gap-6">
        {/* Left Panel - Sticky App Promotion */}
        <div className="max-w-[320px] md:w-1/3">
          <div className="sticky top-[72px]">
            <div className="rounded-[40px] border border-gray-200 px-12 py-6 shadow-sm">
              <div className="flex flex-col items-center">
                <Image
                  src="/images/app_icon.png"
                  alt="App Icon"
                  width={60}
                  height={60}
                  className="mt-4 rounded-xl shadow-sm"
                />
                <div className="flex flex-col px-4">
                  <h1 className="mt-6 text-center text-lg font-bold leading-5">
                    brek - Mom life guide
                  </h1>
                  <span className="text-md mt-2 text-center font-normal text-gray-500">
                    Join thousands of moms to share experiences & tips
                  </span>
                </div>
                <div className="mb-4 mt-8 flex flex-row justify-center gap-3">
                  <Link
                    href="https://apps.apple.com/app/id6504866617"
                    onClick={() => {
                      capture("app_store_button_click", {
                        platform: "ios",
                        location: "home",
                      });
                    }}
                  >
                    <Image
                      src="/images/app_store.svg"
                      alt="App Icon"
                      width={120}
                      height={40}
                    />
                  </Link>
                  <Link
                    href="https://play.google.com/store/apps/details?id=com.pageonelab.brekapp"
                    onClick={() => {
                      capture("app_store_button_click", {
                        platform: "android",
                        location: "home",
                      });
                    }}
                  >
                    <Image
                      src="/images/google_play.svg"
                      alt="App Icon"
                      width={120}
                      height={40}
                      className="cursor-pointer hover:shadow-lg"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div id="other-links" className="mt-4 text-sm text-gray-400">
              <div className="flex flex-row justify-center gap-4">
                <Link href="/terms/community-guideline">
                  Community Guidelines
                </Link>
                <Link href="/feedback">Feedbacks</Link>
              </div>
              <div className="mt-2 flex flex-row justify-center gap-4">
                <Link href="/terms/terms-of-service">Terms</Link>
                <Link href="/terms/privacy-policy">Privacy</Link>
                <Link href="/terms/cookies-policy">Cookies</Link>
              </div>
              <div className="mt-4 flex items-center justify-center text-sm text-gray-400">
                &copy; {new Date().getFullYear()} Brek
              </div>
            </div>
          </div>
        </div>

        {/* Right Panel - Infinite Scroll Posts */}
        <div className="md:w-2/3">
          <InfiniteScroll
            isInitialLoading={isInitialLoading}
            loadMore={handleLoadMore}
            hasMore={hasMore}
            isLoadingMore={!isInitialLoading && isMutating}
            error={error}
            onRetry={fetchInitialData}
          >
            <PostList
              isInitialLoading={isInitialLoading}
              posts={feeds}
              onPostClick={onPostClick}
            />
          </InfiniteScroll>
        </div>
      </div>

      {/* Mobile Layout - Only Posts */}
      <div className="w-full max-w-3xl md:hidden">
        <InfiniteScroll
          isInitialLoading={isInitialLoading}
          loadMore={handleLoadMore}
          hasMore={hasMore}
          isLoadingMore={!isInitialLoading && isMutating}
          error={error}
          onRetry={fetchInitialData}
        >
          <PostList
            isInitialLoading={isInitialLoading}
            posts={feeds}
            onPostClick={onPostClick}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FeedPage;
