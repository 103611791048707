import { useCallback } from "react";
import toast, { Toast } from "react-hot-toast";

interface ToastOptions {
  title?: string;
  description: string;
  variant?: "default" | "success" | "error";
}

export const useToast = () => {
  const showToast = useCallback(
    ({ title, description, variant = "default" }: ToastOptions) => {
      const toastOptions: Partial<Toast> = {
        duration: 4000,
        position: "top-center",
      };

      switch (variant) {
        case "success":
          toast.success(description, toastOptions);
          break;
        case "error":
          toast.error(description, toastOptions);
          break;
        default:
          toast(description, toastOptions);
      }
    },
    [],
  );

  return { showToast };
};
