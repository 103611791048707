import { PostDetail } from "@/types/api/post";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../store";

// Interface for post-specific feed data
interface PostFeedEntry {
  feeds: PostDetail[];
  hasMore: boolean;
  scrollPosition: number;
  lastVisited: number; // timestamp for potential cache eviction policy
}

interface FeedState {
  // Define your state interface here
  isInitialLoading: boolean;
  feeds?: PostDetail[];
  homeScrollPosition: number; // To save the scroll position of the home feed
  // Map to store post-specific feeds
  postFeeds: Record<string, PostFeedEntry>;
}

const initialState: FeedState = {
  // Set your initial state here
  isInitialLoading: true,
  homeScrollPosition: 0,
  postFeeds: {},
};

export const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    // Define your reducer actions here
    setFeeds: (state, action: PayloadAction<PostDetail[]>) => {
      state.feeds = action.payload;
    },
    addFeeds: (state, action: PayloadAction<PostDetail[]>) => {
      state.feeds = state.feeds
        ? [...state.feeds, ...action.payload]
        : action.payload;
    },
    clearFeeds: (state) => {
      state.feeds = [];
    },
    setIsInitialLoading: (state, action: PayloadAction<boolean>) => {
      state.isInitialLoading = action.payload;
    },
    saveHomeScrollPosition: (state, action: PayloadAction<number>) => {
      state.homeScrollPosition = action.payload;
    },

    // Post-specific feed actions
    setPostFeeds: (
      state,
      action: PayloadAction<{
        postId: string;
        feeds: PostDetail[];
        hasMore: boolean;
      }>,
    ) => {
      const { postId, feeds, hasMore } = action.payload;
      // Preserve existing scroll position if available
      const existing = state.postFeeds[postId] || {
        scrollPosition: 0,
        lastVisited: Date.now(),
      };

      state.postFeeds[postId] = {
        ...existing,
        feeds,
        hasMore,
        lastVisited: Date.now(),
      };
    },

    addPostFeeds: (
      state,
      action: PayloadAction<{
        postId: string;
        feeds: PostDetail[];
        hasMore: boolean;
      }>,
    ) => {
      const { postId, feeds, hasMore } = action.payload;
      const existing = state.postFeeds[postId];

      if (existing) {
        state.postFeeds[postId] = {
          ...existing,
          feeds: [...existing.feeds, ...feeds],
          hasMore,
          lastVisited: Date.now(),
        };
      } else {
        state.postFeeds[postId] = {
          feeds,
          hasMore,
          scrollPosition: 0,
          lastVisited: Date.now(),
        };
      }
    },
    savePostScrollPosition: (
      state,
      action: PayloadAction<{
        postId: string;
        scrollPosition: number;
      }>,
    ) => {
      const { postId, scrollPosition } = action.payload;

      if (state.postFeeds[postId]) {
        state.postFeeds[postId].scrollPosition = scrollPosition;
        state.postFeeds[postId].lastVisited = Date.now();
      } else {
        state.postFeeds[postId] = {
          feeds: [],
          hasMore: true,
          scrollPosition,
          lastVisited: Date.now(),
        };
      }
    },

    clearPostFeed: (state, action: PayloadAction<string>) => {
      const postId = action.payload;
      if (state.postFeeds[postId]) {
        delete state.postFeeds[postId];
      }
    },

    clearAllPostFeeds: (state) => {
      state.postFeeds = {};
    },
  },
});

export const {
  setFeeds,
  addFeeds,
  clearFeeds,
  setIsInitialLoading,
  saveHomeScrollPosition,
  setPostFeeds,
  addPostFeeds,
  savePostScrollPosition,
  clearPostFeed,
  clearAllPostFeeds,
} = feedSlice.actions;

export const feedState = (state: RootState) => state.feed;

// Selector to get post-specific feed data
export const selectPostFeed = (state: RootState, postId: string) =>
  state.feed.postFeeds[postId];

export default feedSlice.reducer;
